<template>
    <div style="padding: 40px 50px; margin-top: 20%">
           <img alt="checkimg" :src="check" class="thank_img">
           <br>
           <h4 style="font-weight:750; font-size: 23px">Thank you for your participation!</h4>
    </div>
</template>

<script>
export default {
    name: 'ThankYouTwo',
    data() {
        return{
            check: require('./../check.png')
        }
    }
}

</script>

<style scoped>
.Q
{
    margin: 100px auto 30px auto;
    min-width: 30%;
    max-width: 50%;
    height: 100%;
    background-color: white;
    background-position: center;
    background-size: cover;
    border-radius: 25px;
    box-shadow: 0 0 25px 0px rgba(0,0,0,0.5);
}

.thank_img
{
    width: 12%;
    margin-bottom: 20px;
}

@media (max-width: 350px)
{
    .thank_img
    {
        width: 100%;
    }
}

</style>