
<template>

  <div v-if="action == 'qna'" style="justify-content: center" class="Q1">
    <ul v-for="q in qna" :key="q.id" v-show="q.id == questionstep">
      <h1 style="font-weight: bold; color: #110093">Q{{ q.number }}</h1>
      <h2>
        <b> {{ q.title }} </b>
      </h2>
      <div v-html="q.html"></div>

      <div v-if="q.input_type == 'checkbox_multiple'">
        <div v-for="ans in q.answers" :key="ans.id" class="form-check">
            <label class="form-check-label option-style" :for="ans.id">
              <input class="form-check-input" type="checkbox" name="answers" :id="ans.id" :value="ans.id" v-model="newans_multiple" @click="disabledbtn" @change="getInputType(ans.input_type)"/>
              {{ ans.content }}

              <input type="text" v-if="ans.input_type =='checkbox_freetext'" id="inputfield_for_freetext" style="display:none;" class="freetext_field" v-model="textfield_ans">

            </label>
        </div>
      </div>
      <div v-else>
        <div v-for="ans in q.answers" :key="ans.id" class="form-check">
            <label class="form-check-label option-style" :for="ans.id">
              <input class="form-check-input" type="radio" name="answers" :id="ans.id" :value="ans.id" v-model="newans" @click="disabledbtn" @change="getInputType(ans.input_type)"/>
              {{ ans.content }}
              <input type="text" v-if="ans.input_type =='checkbox_freetext'" id="inputfield_for_freetext" style="display:none;" class="freetext_field" v-model="textfield_ans">
            </label>
        </div>
      </div>


      <button @click="next(questionstep), storeAns(), storetextAns(), scrollTop()" class="next-btn" v-if="questionstep < qna.length" type="submit">
        Next
      </button>


      <button @click="storeAns(), storetextAns(), submit(), showloading(), scrollTop()" class="next-btn" type="submit" v-if="questionstep == qna.length" v-bind="q.next_action" :disabled="btndisabled">
          Get the Result
          <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>
      </button>
    </ul>

    <!-- <div style="display: flex;align-items: center;justify-content: center;">
                <input type="radio" name="genderQ" id="female-opt" value="Female" @change="onChange"/>
                <label>Female</label>
            </div> -->
  </div>

  <ResultPage v-if="action == 'completed'" :storedresult="this.results" />
  <ThankYouOne v-if="nextAction == 'terminated'" />
  <ThankYouTwo v-if="action == 'multiple'" />
</template>

<script>
import axios from "axios";
import ThankYouOne from "./ThankYouOne.vue";
import ThankYouTwo from "./ThankYouTwo.vue";
import ResultPage from "./ResultPage.vue";

// import useValidate from '@vuelidate/core'
// import {required} from '@vuelidate/validators'


export default {
  name: "SurveyQuestion",
  components: {
    ThankYouOne,
    ResultPage,
    ThankYouTwo,
  },


  data() {
    return {
      qna: [],
      text: '',
      questionstep: 1,
      loading: false,     // loading icon not shown by default
      props: {
        formValue: {
          answer: Object,
        },
      },
      btndisabled: true,  // the button is disabled by default
      form: {},
      submitqna: [],      //store and submit the selected answers in array
      slug: "",
      newans_multiple: [],
      action: "qna",
      results: "",
      currentOpt: null    // define the variable first as this variable is null by default, then the value will get from database
    };
  },
  // computed:{
  //       testing: function(){
  //         return this.newans ? false : true;
  //     }

  //     },

  emits: ["formValueChange"],

  methods: {

    showloading(){      //show loading icon when the Submit Result button is clicked
      this.loading = !false
    },

    next() {      //for the next button to proceed to the next page
     let selectedOptionType = this.currentOpt;      //the selectedOptionType is another variable that store either "checkbox_freetext" or "checkbox" that get from database
     let textfield = document.getElementById('inputfield_for_freetext');      // to grab which element need to be checked, so grab its id

     if (selectedOptionType == 'checkbox_freetext' && textfield.value.trim() === '')      // textfield.value means the value of textfield element, then trim is trm the whitespace, so submit the input with just whitespace is not allowed
     {
      //  if selected option is free text && empty
       alert('The input field is required for Others option')
       return

     }else if(!selectedOptionType){     //this statement is to check if there is no option is selected, the next btn cannot be clicked. Instead of disable the btn, alert box is used for the checking
       this.newans = null;      // must set the selected answer's id (newans) become null, or else the answer's id will store the previos answer's id then put to the array, then there will be extra same answer and question that might affect the backend calculation
       alert('At least one option must be selected')
       return
     }
      this.questionstep += 1;
      this.btndisabled = true;
      this.currentOpt = null;     //the type of selected option will become null again everytime when the next() method is triggered, so the alert box can pop up when click next btn with no option

    },

    disabledbtn(){
        this.btndisabled = false    // Enable the btn when option is selected, this function is called when the <label> is clicked
    },

    getInputType(option){     //Identify the answer option type from database, to check whether the option requires a input text box or not
        this.currentOpt = option;     //define a variable here in order to pass the variable to the next() method, the value stored in this variable is either checkbox or checkbox_freetext that get from database
        if(option == "checkbox_freetext"){
            document.getElementById("inputfield_for_freetext").style.display = "block";

        }else{
            document.getElementById("inputfield_for_freetext").style.display = "none";
        }
    },

    storeAns() {

      this.btndisabled = true   //straight away disable the button when storing the answer, so the user can only click 1 tiime

      if (this.newans) {      //store the selected answer and answer id in the array of submitqna
        this.submitqna.push(this.newans);
        this.newans = null;

      }else if(this.newans_multiple.length > 0)
      {

        this.submitqna.push(this.newans_multiple.join('|'));
        this.newans_multiple = null;
      }
      console.log("newans:"+this.newans)
      console.log(this.submitqna);

      // next action
      if (this.nextAction == "terminated") {      //if the next action that pass from database is 'terminate', then:
        this.questionstep = false                 //    no need proceed to next question
        this.submit();                            //    then just submit the answer
      }
    },
    // onChange (e){
    //     this.$emit('formValueChange', {
    //         label: 'Q1',
    //         data:{
    //             ...this.formValue,
    //             [e.target.name]: e.target.key
    //         }
    //     })
    // },

    storetextAns(){
      if(this.textfield_ans){
        this.freetext = this.textfield_ans
      }
    },

    scrollTop(){      //allow the windows scroll to top, this function called when the Next btn is clicked
      window.scrollTo(0,0);
    },


    async submit() {      //submit the result to the backend
    const data = {
          qna: this.submitqna,
          text: this.freetext,
          slug: window.location.pathname.replace(/\//, ""),
        }
      await axios({
        method: "post",
        // url: `${process.env.VUE_APP_URL}/survey/submit-result`,
        url: `https://panel.engagelife.com.my/survey/submit-result?slug=${window.location.pathname.replace(/\//, '')}&${Object.keys(data).map(key => key + '=' + data[key]).join('&')}`,
        data: {},
        headers: {
        "Content-Type": "text/plain"
      }
      }).then(
        (response) => (
          (this.action = response.data.data.action),
          (this.results = response.data.data.results)
        )
      );
      console.log(this.action);
      console.log(this.results);
    },
    // async submit() {      //submit the result to the backend
    // const data = {    //data that submit to backend
    //       qna: this.submitqna,
    //       text: this.freetext,
    //       slug: window.location.pathname.replace(/\//, ""),
    //     }
    //   await axios({
    //     method: "post",
    //     // url: `${process.env.VUE_APP_URL}/survey/submit-result`,
    //     url: "https://survey-fitxcapes.testlab360.com/survey/submit-result",
    //     data: {
    //       qna: this.submitqna,
    //       text: this.freetext,
    //       slug: window.location.pathname.replace(/\//, ""),
    //     },
    //   }).then(
    //     (response) => (
    //       (this.action = response.data.data.action),
    //       (this.results = response.data.data.results)
    //     )
    //   );
    //   console.log(this.action);
    //   console.log(this.results);
    // },
  },
  mounted() {
    this.$root.$emit("incrementCountEvent");
    // axios
    //     .post('https://survey-fitxcapes.testlab360.com/survey/get-question')
    //     .then((response) => (console.log(response)))
    axios({
      method: "post",
      // url: `${process.env.VUE_APP_URL}/survey/get-question`,
      url: `https://panel.engagelife.com.my/survey/get-question?slug=${window.location.pathname.replace(/\//, '')}`,
      headers: {
        "Content-Type": "text/plain"
      }
    }).then((response) => (this.qna = response.data.data.qna));

    // axios({
    //     method : 'post',
    //     url: 'https://survey-fitxcapes.testlab360.com/survey/submit-result',
    //     data:{
    //         slug: window.location.pathname.replace(/\//, '')
    //     },
    // })
    // .then((response) => (action = response.data.action))
    // console.log(this.action)
  },
  computed: {
    // currentQuestion() {
    //     return this.qna.reduce((acc, obj, index) => {
    //         if(!acc) {
    //             return index == this.questionStep - 1 ? obj : null
    //         }
    //         return acc
    //         }, null);
    // },
    answers() {
      return this.qna.flatMap((q) => q.answers);
    },
    nextAction() {
      return this.answers.reduce((action, ans) => {
        if (!action) {
          return this.submitqna.find((e) => e === ans.id)
            ? ans.next_action
            : null;
        }

        return action;
      }, null);
    },
  },
};
</script>

<style scoped>
.font-style {
  color: red;
}

ul {
  list-style: none;
  padding: 0;
  margin-left: 60px;
  margin-right: 60px;
}

.next-btn {
  background-color: #110093;
  border: none;
  color: white;
  border-radius: 30px;
  font-weight: bold;
  margin: 20px 10px 10px 10px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 30px;
  padding: 12px 32px;
}


.Q1 {
  padding: 40px 30px 20px 30px;
}

.option-style {
  background-color: #f5f5f5;
  margin: 10px 0px 10px 0px;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 18px;
  padding-left: 45px;
  border-radius: 30px;
  width: 100%;

  cursor: pointer;
}

.disablebtncolor
{
    background-color: black;
}

.option_content
{
  text-align: justify;
  margin-left: 10px;
}

.form-check
{
  padding-left: 0px;
}

.spinner-border-sm
{
    --bs-spinner-width: 1.2rem;
    --bs-spinner-height: 1.2rem;
    --bs-spinner-border-width: 0.2em;
    margin-left: 10px;
}

.freetext_field{
  border-radius: 15px;
  height:45px;
  width:100%;
  margin-left:20px;
  margin: 15px 0 0 0;
  border:none;
  padding-left:20px;
  box-shadow: inset -2px -2px 7px rgba(255, 255, 255, 0.8),
           inset 2px 2px 7px rgba(23, 23, 23, 0.3);
}


@media (max-width: 650px) {

  .Q1 {
    margin: 0px;
    padding: 20px;
    padding-top: 35px;
  }
  .next-btn {
    background-color: #110093;
    border: none;
    color: white;
    border-radius: 30px;
    font-weight: bold;

    font-size: 18px;
    font-weight: 700;
    border-radius: 30px;
    padding: 12px 28px;
  }
  ul {
    margin-left: 25px;
    margin-right: 25px;
}
}

@media(max-width: 500px)
{
  .option_content
  {
    text-align: left;
  }
}

</style>