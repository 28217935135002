<template>
    <div class="Q1">

           <h1 style="font-weight:bold; color:white; margin-top: 20%">404 Error</h1>
           <h4 style="color:white;">Page Not Found</h4>
    </div>
</template>

<script>
export default {
    name: 'PageNotFound',
}

</script>