
<template>
    <div class="Q1">

           <h1 style="font-weight:800; color:#110093">MYWELLNESS</h1>
           <br>
           <h5><b>Hello!</b></h5>
           <h5><b>Welcome to the MyWellness Questionnaire.</b></h5>
           <br><br>
           <p>This questionnaire is to help you discover your thoughts, actions, and preferences towards health and wellness.<br><br>
                There are no right or wrong answers. Just be true to yourself!
                <br><br>This questionnaire will take only <span style="font-weight:bold">5 minutes</span> to complete.
                <br><br>Ready? Let’s Go!
            </p>


    </div>
</template>


<script>

export default {
  name: 'WelcomePage',
}
</script>

<style scoped>

/* .Q
{
    margin: 100px auto 30px auto;
    max-width: 55%;
    height: 100%;
    background-color: white;
    background-position: center;
    background-size: cover;
    border-radius: 25px;
    padding: 10px 20px;
    box-shadow: 0 0 25px 0px rgba(0,0,0,0.5);
} */
.Q1
{
    padding: 40px 30px 20px 30px;
}

section h2
{
    align-content: flex-start;
}


.next_btn
{
    background-color: #110093;
    border: none;
    padding: 10px 40px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 10px 32px 10px;
    color: white;
}

.next_btn:hover
{
    transform: scale(106%);
}
</style>